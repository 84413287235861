import './assets/libs/boxicons-2.1.1/css/boxicons.min.css';
import './scss/App.scss';
import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom';
import { useState, useEffect } from 'react';
import Blank from './pages/Blank';
import Dashboard from './pages/Dashboard';
import MainLayout from './layout/MainLayout';
import Products from './pages/Products';
import ProductDetails from './pages/ProductsDetails';
import LoginForm from './pages/LoginForm';
import UsersList from './pages/UserList';
import OrdersList from './pages/OrderList';
import EditOrder from './pages/EditOrder';
import Stock from './pages/Stock';
import Offer from './pages/Offer';
import UserDetail from './pages/UserDeatail';
import OrderProduct from './pages/OrderProduct';
import ProductRequestList from './pages/ProductRequestList';

function App() {
    const [isAuthenticated, setIsAuthenticated] = useState(false);
    const [loading, setLoading] = useState(true);  // New loading state

    useEffect(() => {
        // Check for token in local storage
        const token = localStorage.getItem('authToken');
        if (token) {
            setIsAuthenticated(true);
        }
        setLoading(false); // Stop loading after checking authentication
    }, []);

    const handleLogout = () => {
        localStorage.removeItem('authToken');
        setIsAuthenticated(false);
    };

    // Show a loading indicator while authentication status is being determined
    if (loading) {
        return <div>Loading...</div>;  // You can replace this with a spinner or a custom loading component
    }

    return (
        <BrowserRouter>
            <Routes>
                {/* Login Route */}
                <Route path="/login" element={<LoginForm setIsAuthenticated={setIsAuthenticated} />} />

                {/* Protected Routes - Only show MainLayout if authenticated */}
                {isAuthenticated ? (
                    <Route path="/" element={<MainLayout handleLogout={handleLogout} />}>
                        <Route index element={<Dashboard />} />
                        <Route path="orders" element={<OrdersList />} />
                        <Route path="order-detail/:orderId" element={<EditOrder />} />
                        <Route path="products" element={<Products />} />
                        <Route path="product/:medicine_id" element={<ProductDetails />} />
                        <Route path="clients" element={<UsersList />} />
                        <Route path="client/:userId" element={<UserDetail />} />
                        <Route path="stock" element={<Stock />} />
                        <Route path="offer" element={<Offer />} />
                        <Route path="order-product" element={<OrderProduct />} />
                        <Route path="request" element={<ProductRequestList />} />
                    </Route>
                ) : (
                    // Redirect to login if not authenticated
                    <Route path="*" element={<Navigate to="/login" />} />
                )}
            </Routes>
        </BrowserRouter>
    );
}

export default App;
